var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticStyle: {
      "height": "200%"
    }
  }, [_c('span', {
    staticClass: "text-body-2"
  }, [_vm._v("My Mount Glass & Glazing")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }