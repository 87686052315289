<template>
  <v-container style='height: 200%;'>
    <span class='text-body-2'>My Mount Glass &amp; Glazing</span>
  </v-container>
</template>

<style lang="scss">
</style>


<script>
import { mapActions } from 'vuex';
export default {
  name: 'About',
  components: {
  },
  watch:{
    $route: { handler(){ let t = `About`; this.SET_TITLE(t); }, immediate: true },
  },
  methods: {
    ...mapActions("UI", ['SET_TITLE']),
  },
  mounted(){
  }
}
</script>
